<template>
  <div>
    <b-row align-h="center">
      <b-col
        md="6"
        lg="6"
      >
        <div class="card">
          <div class="card-body text-center">
            <h6 class="h5 mb-4">
              <span v-if="title">{{title}}</span>
              <span v-else>
                กรุณากรอก Secure code 6 หลักด้านล่าง
              </span>
            </h6>
            <ValidationObserver
              ref="verifyLoginForm"
              v-slot="{ handleSubmit }"
            >
              <b-form
                class="authentication-form"
                @submit.prevent="handleSubmit(onSubmit)"
              >
                <b-form-group>
                  <b-form-input
                    id="secureCode"
                    v-model="secureCode"
                    placeholder="Secure code"
                    readonly
                    style="text-align: center"
                    type="password"
                  ></b-form-input>
                </b-form-group>
                <b-row>
                  <b-col
                    v-for="(pad, i) in keypad"
                    :key="i"
                    cols="4"
                    class="mb-1"
                  >
                    <b-button
                      pill
                      variant="outline-info"
                      block
                      @click="handleClickKeypad(pad)"
                    >{{ pad.value }}</b-button>
                  </b-col>
                </b-row>
                <div class="my-2">
                  <b-button
                    variant="dark"
                    class="mr-2"
                    @click="onCancel"
                  >
                    {{ $t('buttons.cancel') }}
                  </b-button>
                  <b-overlay
                    :show="isPinLoggingIn"
                    rounded
                    spinner-small
                    class="d-inline-block"
                  >
                    <b-button
                      type="submit"
                      variant="primary"
                      block
                      :disabled="secureCode.length < 6"
                    >
                      {{ $t('buttons.save') }}
                    </b-button>
                  </b-overlay>
                </div>
              </b-form>
            </ValidationObserver>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'ยืนยันการเข้าสู่ระบบ',
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      secureCode: '',
      keypad: [
        { key: '1', value: 1 },
        { key: '2', value: 2 },
        { key: '3', value: 3 },
        { key: '4', value: 4 },
        { key: '5', value: 5 },
        { key: '6', value: 6 },
        { key: '7', value: 7 },
        { key: '8', value: 8 },
        { key: '9', value: 9 },
        { key: 'clr', value: 'ล้าง' },
        { key: '0', value: 0 },
        { key: 'del', value: 'ลบ' },
      ],
    }
  },
  computed: {
    ...mapState(['auth']),
    ...mapGetters(['brandName', 'brandLogo']),
    isLoading() {
      const { isLoggingIn } = this.auth
      return isLoggingIn
    },
    isPinLoggingIn() {
      return this.auth.isPinLoggingIn
    },
  },
  created() {
    // this.randomKeypad()
  },
  methods: {
    ...mapActions(['login', 'fetchBrands']),
    randomKeypad() {
      const char = '0123456789'
      let randomChar = ''
      for (let i = 0; randomChar.length < 10; i++) {
        const rand = Math.round(Math.random() * 10)
        if (randomChar.indexOf(rand) > -1) continue
        randomChar += char.substring(rand, rand + 1)
      }
      let counter = 0
      while (counter < 10) {
        const foundKeypadI = this.keypad.findIndex(
          ({ key }) => key === `${counter}`
        )
        this.keypad[foundKeypadI].value = randomChar[counter]
        counter++
      }
    },
    handleClickKeypad(selectedKeypad) {
      if (selectedKeypad.key === 'clr') {
        this.secureCode = ''
      } else if (selectedKeypad.key === 'del') {
        this.secureCode = this.secureCode.slice(0, -1)
      } else {
        if (this.secureCode.length === 6) {
          return
        }
        this.secureCode += selectedKeypad.value
      }
    },
    onSubmit() {
      this.$emit('submit', this.secureCode)
      this.secureCode = ''
    },
    onCancel() {
      window.location.reload()
    },
  },
}
</script>
